import React, { Component } from "react";

export default class MaleHormone extends Component {
  
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://formfacade.com/include/100696475651717806610/form/1FAIpQLSe2X_jsPGdSCYo0A7Xk4xwzs5KESqFpkgisY_0bqJEqjlallw/classic.js?div=ff-compose";
    script.async = true;

    document.body.appendChild(script);
  }
  
  render() {
    return (
      <div style={{paddingTop: 100}}>
        <div id="ff-compose" />
        <div className="container" />
      </div>
    );
  }
}