import React, { Component } from "react";

export default class FemaleHormone extends Component {
  
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://formfacade.com/include/100696475651717806610/form/1FAIpQLScEiLsoG-ylZKJdMDGf2hX2MHW0II1FjZCYKDd4Rgo-GhkM6A/classic.js?div=ff-compose";
    script.async = true;

    document.body.appendChild(script);
  }
  
  render() {
    return (
      <div style={{paddingTop: 100}}>
        <div id="ff-compose" />
        <div className="container" />
      </div>
    );
  }
}