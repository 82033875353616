import React, { Component } from "react";
import MaleHormone from "../components/maleHormone"
import FemaleHormone from "../components/femaleHormone"
import { Button, Grid, Typography } from "@material-ui/core";



export default class Hormones extends Component {
  state = {
    gender: ""
  };

  genderSwitch(gender: any) 
  {
    switch (gender) 
    {
      case "Male":
        return <MaleHormone />;

      case "Female":
        return <FemaleHormone />;

      default:
        return (
          <Grid
            container
            spacing={6}
            style={{
              textAlign: "center",
              padding: 20,
              paddingTop: 100,
              paddingBottom: "90vh",
              justifyContent: "center",
            }}
          >
            <Typography variant="h4" color="primary">
              Please select your gender
            </Typography>
            <Grid
              container
              spacing={6}
              style={{
                textAlign: "center",
                minHeight: 20,
                padding: 20,
                paddingTop: 30,
                justifyContent: "center"
              }}
            >
              <Button
                id="male"
                value="Male"
                variant="contained"
                color="secondary"
                size="large"
                style={{ marginRight: 20 }}
                onClick = {() => this.setState({gender:"Male"})}
              >
                Male
              </Button>
              <Button
                id="female"
                value="Female"
                variant="contained"
                color="secondary"
                size="large"
                onClick = {() => this.setState({gender:"Female"})}

              >
                Female
              </Button>
            </Grid>
          </Grid>
        );
    }
  }

  render() {
    let genderForm = this.genderSwitch(this.state.gender);

    return genderForm ;
  }
}